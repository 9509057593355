@import "../../App";


.RemindMeWrapper {
    padding: 15px;

    .RemindMeTimeChoiceWrapper,
    .RemindMeDayChoiceWrapper {
        margin: 0 auto;
        margin-top: 2.5rem;

        h2 {
            @include headlineMedium;
            margin-top: 1.5rem
        }

        h3 {
            @include subtitle;
            margin-top: 1.5rem;
            margin-bottom: 2rem;

        }
    }


    .RemindMeDaysWrapper {
        margin: 2rem auto;
        display: flex;
        justify-content: space-evenly;

        button {
            width: 4rem;
            border-radius: 50%;
            height: 4rem;
        }

        .brightDay {
            border: 2px solid $beigeColor;
            background-color: transparent;
            color: $darkGreenColor;
        }

        .darkDay {
            border: 2px solid $lightGreenColor;
            background-color: $lightGreenColor;
            color: $beigeColor;
        }
    }


    .RemindMeBtnWrapper {
        margin: 3rem auto;

        .noThxBtn {
            display: block;
            color: $orangeColor;
            border: none;
            border-radius: 30px;
            font-size: 1.6rem;
            margin: 3rem 0;
            text-decoration: none;
            text-align: center;
            font-family: $SSP600;
        }

    }

    @media screen and (min-width: $small) {

        .RemindMeDaysWrapper {
            button {
                width: 6rem;
                height: 6rem;
                font-size: 1.3rem;


            }

        }
    }

    @media screen and (min-width: $medium) {

        .RemindMeDaysWrapper {
            button {
                width: 7.5rem;
                height: 7.5rem;
                font-size: 1.4rem;


            }

        }
    }

}