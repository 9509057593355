@import '../../App';

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.loading {
    width: 6rem;
    height: 6rem;
    border: 5px solid $grayColor;
    border-radius: 50%;
    border-top-color: $orangeColor;
    animation: spinner 1.5s linear infinite;
    overflow: hidden;
}

.loading-center {
    margin: 0 auto;
}