@import '../../App';

.startSection {
    background-image: url('../../assets/img/startImg.png');
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position: top;
    background-size: 100% auto;
    padding: 5vh 0 7vh 0;
    color: $whiteColor;

    .startHeading {
        text-align: center;
        font-size: 1.6rem;
        font-family: $SSP700;
    }

    .startParagraph {
        width: 180px;
        margin: 30px 0 0 40px;
        font-family: $SSP900;
        font-size: 3.6rem;
    }
}