@import '../../App';

.brandName {
    text-align: center;
    padding-top: 1.5rem;
    font-size: 1.6rem;
    font-family: $SSP700;
    color: $darkGreenColor;

    @media screen and (min-width: $small) {
        font-size: 1.8rem;
    }

    @media screen and (min-width: $medium) {
        font-size: 2rem;
    }

    @media screen and (min-width: $large) {
        font-size: 2.3rem;
    }
}