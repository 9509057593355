@import '../../App';

.player_container {
    width: 80%;
    padding: 1rem;
    border-radius: 10px;
    color: rgb(218, 218, 218);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    >* {
        padding: 1rem 0;
        color: rgb(202, 202, 202);
    }

    .title {
        p {
            font-size: 2.5rem;
        }
    }

    .navigation {
        width: 100%;


        .navigation_wrapper {
            min-width: 100%;
            background-color: rgba(119, 119, 119, 0.781);
            height: 10px;
            border-radius: 30px;
            cursor: pointer;

            .seek_bar {
                width: 0;
                height: 100%;
                background-color: rgb(24, 128, 3);
                border-radius: 30px;
            }

        }
    }

    .controls {
        font-size: inherit;
        display: flex;
        align-items: center;

        .btn_action {
            font-size: 5rem;
            margin: 0 1rem;
            cursor: pointer;

            &:hover {
                color: white;
            }

        }

        .pp {
            font-size: 7rem;
            color: $darkGreenColor;
        }
    }
}