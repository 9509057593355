@import '../../App';

.topNavArticle {
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 20px 10px;
    justify-content: space-between;
    position: fixed;
    z-index: 2;

    div {

        img {
            height: 50px;
            margin-left: 10px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}