@import "../../App";

.musicDetailPage {

    h2 {
        @include headlineBig;
        text-align: center;
        margin-bottom: 2rem
    }

    h4 {
        @include subtitle;
        text-align: center;
        margin-bottom: 1rem;

        &:nth-of-type(2) {
            margin-bottom: 4rem;
        }
    }

    >section {
        margin-bottom: 10rem;

        .musicDetailShowMore {
            display: block;
            width: 5rem;
            height: 3rem;
            border: 1px solid dimgray;
            border-radius: 50px;
            font-size: 1.8rem;
            background-color: transparent;
            text-align: center;
            margin: 3rem auto;
        }

        .musicDetailUpBtn {
            width: 6rem;
            height: 6rem;
            position: fixed;
            bottom: 10rem;
            right: 1rem;
            border-radius: 50%;
            padding: 0.5rem;
            border: 1px solid $grayColor;
            background-color: whitesmoke;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

    }

}