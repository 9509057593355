@import "../../App";

.musicPage {
    h2 {
        @include headlineBig;
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 3rem
    }

    h4 {
        @include subtitle;
        text-align: center;
        margin-bottom: 1rem;

        &:nth-of-type(2) {
            margin-bottom: 4rem;
        }
    }

    section {
        a {
            text-decoration: none;
            display: flex;
            justify-content: space-between;


            width: 80%;
            margin: 0 auto;

            article {
                margin: .6rem 0;

                &:nth-of-type(1) {
                    width: 30%;
                    height: 100px;
                    border-radius: 50%;

                    img {
                        // width: 100%;
                        height: 100%;
                        aspect-ratio: 1/1;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 50%;
                    }
                }

                &:nth-of-type(2) {
                    width: 60%;
                    display: flex;

                    h5 {
                        @include headlineMedium;
                        font-size: 1.8rem;
                        align-self: center;
                    }
                }
            }
        }

    }

    @media screen and (min-width: $small) {

        section {
            a {
                width: 60%;
                justify-content: space-around;
            }
        }
    }

    @media screen and (min-width: $medium) {

        section {
            a {
                width: 50%;
                justify-content: space-around;
            }
        }
    }

    @media screen and (min-width: $large) {

        section {
            a {
                width: 50%;
                justify-content: center;
            }
        }
    }
}