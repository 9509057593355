@import '../../App';

.returnBtnBox {
    display: block;
    padding-top: 4vh;
    padding-left: 7vw;
    width: 100px;


    img {
        width: 100%
    }
}