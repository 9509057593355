@import '../../App';

.searchBarDiv {
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: 4rem;
    position: relative;

    .search {
        position: absolute;
        // margin-top: -1rem;
        padding: 10px;
        width: 100%;
        border: 1px solid black;
        background-color: #ecedf0;
        border-radius: 10px;
        z-index: 2;
        border: none;
        @include subtitle // background-image: url('../../assets/img/lupe.svg');
        // background-repeat: no-repeat;
        // background-position: 320px;
        // background-size: 15px;
    }

    &::before {
        position: absolute;
        content: '';
        background-image: url('../../assets/img/lupe.svg');
        background-size: cover;
        height: 20px;
        width: 20px;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 3;
    }



    .searchResultsHidden {
        display: none;
    }

    .searchResultsShown {
        justify-content: space-around;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        position: absolute;
        background-color: #ecedf0;
        width: 100%;

        margin-top: 1.8rem;
        padding: 3rem 1rem;
        border-radius: 0 0 10px 10px;
        box-shadow: 3px 3px 6px rgb(162, 158, 158);
        z-index: 1;

        &::after {
            content: "";
            flex: auto;
        }


        p {
            @include subtitle;
            font-size: 1.3rem;
        }

        // .searchResultSmallCardWrapper {
        //     width: 145px;
        // }
    }

    @media screen and (min-width: $small) {

        .searchResultsShown {
            justify-content: space-around;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            &::after {
                content: "";
                flex: auto;
            }
        }
    }

    @media screen and (min-width: $medium) {
        height: calc(4rem + 20px);

        .search {
            padding: 20px;
        }

        &::before {
            height: 40px;
            right: 10px;
            width: 40px;
        }

        .searchResultsShown {
            margin-top: calc(1.8rem + 20px);
        }

    }
}