@import '../../App';

.navBarWrapper {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    text-align: center;
    width: 100%;
    position: fixed;
    padding: 10px 0;
    box-shadow: -0px -0px 15px rgb(165, 165, 165);
    background-color: white;
    inset: auto 0 0 0;

    >a {
        text-decoration: none;
        @include subtitle;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 22px;
                height: 22px;
                margin: 7px 7px;
            }

            .active {
                width: 36px;
                height: 36px;
                margin: 0px 0px;
            }

            p {
                margin-top: 10px;
            }
        }
    }
}